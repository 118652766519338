import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import {
	getGapCSS,
	getAlignItemCSS,
	getAlignContentCSS,
	getJustifyContentCSS,
	getGridTemplateColumnsCSS,
	getGridAutoColumnsCSS,
	getGridTemplateRowAreasCSS,
} from './layoutStyleFunctions';
import { Gap } from './layoutDefinitions';

export type ShelfStaticStyleProps = BaseStaticStyleProps & {
	$gap?: Gap;
	$alignItems?: CSSProperty.AlignItems;
	$justifyItems?: CSSProperty.JustifyItems;
	$alignContent?: CSSProperty.AlignContent;
	$justifyContent?: CSSProperty.JustifyContent;
	$columns?: CSSProperty.GridTemplateColumns;
	$autoColumns?: CSSProperty.GridAutoColumns;
	$areas?: Array<string>;
};

export type ShelfComponentStyleProps =
	ComponentStyleProps<ShelfStaticStyleProps>;

export const shelfStaticStyleMap: StyleMap<ShelfStaticStyleProps> = {
	...baseStaticStyleMap,
	$gap: getGapCSS,
	$alignItems: getAlignItemCSS,
	$justifyItems: (justifyContent = 'start') =>
		getJustifyContentCSS(justifyContent),
	$alignContent: getAlignContentCSS,
	$justifyContent: getJustifyContentCSS,
	$columns: getGridTemplateColumnsCSS,
	$autoColumns: getGridAutoColumnsCSS,
	$areas: getGridTemplateRowAreasCSS,
};

const shelfStyleMap: ComponentStyleMap<ShelfStaticStyleProps> = {
	normal: shelfStaticStyleMap,
};

/**
 * Component for placing items horizontally
 *
 * Responsibilities:
 *
 * 1. Place items next to each other
 * 2. Spacing between items
 * 3. Alignment of items
 */
export const Shelf = styled.span<ShelfComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: grid;
			grid-template-rows: minmax(0, 1fr);
			grid-auto-flow: column;
			grid-auto-columns: minmax(0, auto);
			list-style: none;
			${getCSSFromStyleProps(shelfStyleMap, props)};
		`;
	}};
`;

export type ShelfProps = StyledComponentPropsWithoutRef<typeof Shelf>;
