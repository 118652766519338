import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconPlusThin: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '2 2 20 20', width: '20', height: '20' }
			: { viewBox: '0 0 24 24', width: '24', height: '24' })}
		role="img"
		aria-hidden={true}
		{...props}
	>
		<path d="M12 2q0.414 0 0.707 0.293t0.293 0.707v8h8q0.414 0 0.707 0.293t0.293 0.707-0.293 0.707-0.707 0.293h-8v8q0 0.414-0.293 0.707t-0.707 0.293-0.707-0.293-0.293-0.707v-8h-8q-0.414 0-0.707-0.293t-0.293-0.707 0.293-0.707 0.707-0.293h8v-8q0-0.414 0.293-0.707t0.707-0.293z" />
	</svg>
);
