import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import {
	getGridTemplateRowsCSS,
	getGridAutoRowsCSS,
	getGridTemplateColumnAreasCSS,
} from './layoutStyleFunctions';

export type StackStaticStyleProps = BaseStaticStyleProps & {
	$gap?: number;
	$alignItems?: CSSProperty.AlignItems;
	$justifyItems?: CSSProperty.JustifyItems;
	$alignContent?: CSSProperty.AlignContent;
	$justifyContent?: CSSProperty.JustifyContent;
	$rows?: CSSProperty.GridTemplateRows;
	$autoRows?: CSSProperty.GridAutoRows;
	$areas?: Array<string>;
};

export type StackComponentStyleProps =
	ComponentStyleProps<StackStaticStyleProps>;

export const stackStaticStyleMap: StyleMap<StackStaticStyleProps> = {
	...baseStaticStyleMap,
	$gap: (gap = 0) =>
		css`
			gap: calc(var(--root-cap) * ${gap});
		`,
	$alignItems: (alignItems) =>
		css`
			align-items: ${alignItems};
		`,
	$justifyItems: (justifyItems) =>
		css`
			justify-items: ${justifyItems};
		`,
	$alignContent: (alignContent) =>
		css`
			align-content: ${alignContent};
		`,
	$justifyContent: (justifyContent) =>
		css`
			justify-content: ${justifyContent};
		`,
	$rows: getGridTemplateRowsCSS,
	$autoRows: getGridAutoRowsCSS,
	$areas: getGridTemplateColumnAreasCSS,
};

const stackStyleMap: ComponentStyleMap<StackStaticStyleProps> = {
	normal: stackStaticStyleMap,
};

/**
 * Component for stacking items vertically
 *
 * Responsibilities:
 *
 * 1. Stack items on top of each other
 * 2. Spacing between items
 * 3. Alignment of items
 */
export const Stack = styled.span<StackComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: grid;
			grid-template-columns: minmax(0, 1fr);
			grid-auto-flow: row;
			grid-auto-rows: minmax(0, auto);
			${getCSSFromStyleProps(stackStyleMap, props)};
		`;
	}};
`;

export type StackProps = StyledComponentPropsWithoutRef<typeof Stack>;
