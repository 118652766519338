import { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { FlattenSimpleInterpolationMaybe } from '../base/StyleProps.types';
import { TextColor, TextThemedColor } from '../colorTheme/ColorTheme.types';
import { getThemedColorValue } from '../colorTheme/colorThemeStyleFunctions';
import { getRootCapStyling } from '../font/rootCapsize';
import { CapSize, FontVariantNumeric } from './typographyDefinitions';
import {
	activeDescendantsSelector,
	activeSelfSelector,
	hoverDescendantsSelector,
	hoverSelfSelector,
} from '../base/getCSSFromStyleProps';

export const getDefaultCapSize = (capSize?: CapSize) => {
	const [capHeight = 1, leadingRatio = 2.4] = Array.isArray(capSize)
		? capSize
		: [capSize];

	return { capHeight, leadingRatio };
};

export const getCapSizeContextCSS = (capSize?: CapSize) => {
	if (capSize == null) return undefined;

	const { capHeight, leadingRatio } = getDefaultCapSize(capSize);

	const rootCapsizeStyling = getRootCapStyling(capHeight, leadingRatio);

	return css`
		--cap-height: calc(var(--root-cap) * ${capHeight});
		--font-size: ${rootCapsizeStyling.fontSize};
		--line-height: ${rootCapsizeStyling.lineHeight};
		--text-block-margin-before: ${rootCapsizeStyling['::before']
			.marginBlockEnd};
		--text-block-margin-after: ${rootCapsizeStyling['::after']
			.marginBlockStart};
	`;
};

export const getCapSizeCSS = (
	capSize?: CapSize
): FlattenSimpleInterpolationMaybe => {
	const { capHeight, leadingRatio } = getDefaultCapSize(capSize);

	const rootCapsizeStyling = getRootCapStyling(capHeight, leadingRatio);
	const {
		fontSize,
		lineHeight,
		'::before': { marginBlockEnd: marginBefore, ...beforeStyling },
		'::after': { marginBlockStart: marginAfter, ...afterStyling },
	} = rootCapsizeStyling;

	if (capSize == null) {
		return css`
			font-size: var(--font-size, ${fontSize});
			line-height: var(--line-height, ${lineHeight});

			&::before {
				${beforeStyling as typeof beforeStyling & Record<string, string>};
				margin-block-end: var(--text-block-margin-before, ${marginBefore});
			}
			&::after {
				${afterStyling as typeof afterStyling & Record<string, string>};
				margin-block-start: var(--text-block-margin-after, ${marginAfter});
			}
		`;
	}

	return css`
		--cap-height: calc(var(--root-cap) * ${capHeight});
		--font-size: ${fontSize};
		--line-height: ${lineHeight};
		--text-block-margin-before: ${marginBefore};
		--text-block-margin-after: ${marginAfter};

		font-size: var(--font-size, ${fontSize});
		line-height: var(--line-height, ${lineHeight});

		&::before {
			${beforeStyling as typeof beforeStyling & Record<string, string>};
			margin-block-end: var(--text-block-margin-before, ${marginBefore});
		}
		&::after {
			${afterStyling as typeof afterStyling & Record<string, string>};
			margin-block-start: var(--text-block-margin-after, ${marginAfter});
		}
	`;
};
export const getFontWeightCSS = (
	fontWeight?: CSSProperty.FontWeight
): FlattenSimpleInterpolationMaybe =>
	fontWeight != null &&
	css`
		font-weight: ${fontWeight};
	`;
export const getFontStyleCSS = (
	fontStyle?: CSSProperty.FontStyle
): FlattenSimpleInterpolationMaybe =>
	fontStyle != null &&
	css`
		font-style: ${fontStyle};
	`;
export const getFontVariantNumericCSS = (
	fontVariantNumeric: FontVariantNumeric = 'oldstyle-nums'
): FlattenSimpleInterpolationMaybe =>
	css`
		font-variant-numeric: ${fontVariantNumeric};
	`;
export const getTextDecorationLineCSS = (
	textDecorationLine?: Extract<
		CSSProperty.TextDecorationLine,
		CSSProperty.All | 'none' | 'underline' | 'inherit'
	>
): FlattenSimpleInterpolationMaybe => {
	if (textDecorationLine == null) return;
	if (textDecorationLine === 'underline') {
		return css`
			text-decoration-line: underline;
			text-underline-offset: 2px;
			text-decoration-thickness: 1px;

			${hoverSelfSelector},
			${hoverDescendantsSelector} {
				text-decoration-thickness: 2px;
			}

			${activeSelfSelector},
			${activeDescendantsSelector} {
				text-decoration-thickness: 2px;
			}
		`;
	}

	return css`
		text-decoration-line: ${textDecorationLine};
	`;
};

export const getInteractiveTextDecorationLineCSS = (
	textDecorationLine?: Extract<
		CSSProperty.TextDecorationLine,
		CSSProperty.All | 'none' | 'underline'
	>
): FlattenSimpleInterpolationMaybe => {
	if (textDecorationLine == null) return;

	if (textDecorationLine === 'underline') {
		return css`
			text-decoration-line: underline;
			text-underline-offset: 2px;
			text-decoration-thickness: 1px;
		`;
	}

	return css`
		text-decoration-line: ${textDecorationLine};
	`;
};

const defaultColor: TextThemedColor = ['text', 'normal'];

export const getNormalTextContextColorCSS = (
	color: TextColor = defaultColor
): FlattenSimpleInterpolationMaybe => {
	return css`
		--color: ${getThemedColorValue(color, 'normal')};
		--color-hover: ${getThemedColorValue(color, 'hover')};
		--color-active: ${getThemedColorValue(color, 'active')};
	`;
};
export const getHoverTextContextColorCSS = (
	color?: TextColor
): FlattenSimpleInterpolationMaybe => {
	return (
		color != null &&
		css`
			--color-hover: ${getThemedColorValue(color, 'hover')};
		`
	);
};
export const getActiveTextContextColorCSS = (
	color?: TextColor
): FlattenSimpleInterpolationMaybe => {
	return (
		color != null &&
		css`
			--color-active: ${getThemedColorValue(color, 'active')};
		`
	);
};

export const getNormalTextColorCSS = (
	color?: TextColor
): FlattenSimpleInterpolationMaybe => {
	if (color == null) {
		return css`
			color: var(--color, ${getThemedColorValue(defaultColor, 'normal')});

			${hoverSelfSelector},
			${hoverDescendantsSelector} {
				color: var(
					--color-hover,
					${getThemedColorValue(defaultColor, 'hover')}
				);
			}

			${activeSelfSelector},
			${activeDescendantsSelector} {
				color: var(
					--color-active,
					${getThemedColorValue(defaultColor, 'active')}
				);
			}
		`;
	}
	return css`
		--color: ${getThemedColorValue(color, 'normal')};
		--color-hover: ${getThemedColorValue(color, 'hover')};
		--color-active: ${getThemedColorValue(color, 'active')};
		color: var(--color);

		${hoverSelfSelector},
		${hoverDescendantsSelector} {
			color: var(--color-hover);
		}

		${activeSelfSelector},
		${activeDescendantsSelector} {
			color: var(--color-active);
		}
	`;
};
export const getHoverTextColorCSS = (
	color?: TextColor
): FlattenSimpleInterpolationMaybe => {
	return (
		color != null &&
		css`
			color: ${getThemedColorValue(color, 'hover')};
		`
	);
};
export const getActiveTextColorCSS = (
	color?: TextColor
): FlattenSimpleInterpolationMaybe => {
	return (
		color != null &&
		css`
			color: ${getThemedColorValue(color, 'active')};
		`
	);
};

export const getTextAlignCSS = (
	textAlign?: CSSProperty.TextAlign
): FlattenSimpleInterpolationMaybe =>
	textAlign != null &&
	css`
		text-align: ${textAlign};
	`;

export const getTextTransformCSS = (
	textTransform?: CSSProperty.TextTransform
): FlattenSimpleInterpolationMaybe =>
	textTransform != null &&
	css`
		text-transform: ${textTransform};
	`;

export const getWordBreakCss = (wordBreak?: CSSProperty.WordBreak) =>
	wordBreak != null &&
	css`
		word-break: ${wordBreak};
	`;

export const getHyphensCss = (hyphens?: CSSProperty.Hyphens) =>
	hyphens != null &&
	css`
		hyphens: ${hyphens};
	`;

export const getWhiteSpaceCSS = (whiteSpace?: CSSProperty.WhiteSpace) =>
	whiteSpace != null &&
	css`
		white-space: ${whiteSpace};
	`;
