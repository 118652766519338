import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import PropTypes from 'prop-types';

import { useMeasure } from '@dop/shared/hooks/useMeasure';

export const useCollapse = (isOpened, { from, to } = {}) => {
	const {
		bounds: { height: innerHeight },
		ref: measureRef,
	} = useMeasure();

	const [mounted, setMounted] = React.useState(isOpened);
	const [fullyOpened, setFullyOpened] = React.useState(false);
	React.useEffect(() => {
		if (isOpened && isOpened !== mounted) {
			setMounted(isOpened);
		}
	}, [isOpened, mounted]);

	const { height, overflow, ...springProps } = useSpring({
		from: { height: 0, overflow: 'hidden', ...from },
		to: {
			height: isOpened && mounted ? innerHeight : 0,
			...to,
		},
		onRest: () => {
			setMounted(isOpened);
			if (isOpened === true) {
				setFullyOpened(true);
			}
		},
		onStart: () => {
			if (isOpened === false) {
				setFullyOpened(false);
			}
		},
	});

	return {
		mounted,
		animationProps: { style: { height, overflow } },
		measureProps: {
			ref: measureRef,
			hidden: !isOpened && !mounted,
			'data-fully-opened': fullyOpened,
		},
		springProps,
	};
};

export const Collapse = ({
	isOpened = false,
	children,
	as: Animated = animated.div,
	...restProps
}) => {
	const { animationProps, measureProps } = useCollapse(isOpened);
	return (
		<Animated {...restProps} {...animationProps}>
			<div {...measureProps}>{children}</div>
		</Animated>
	);
};
Collapse.propTypes = {
	isOpened: PropTypes.bool,
	children: PropTypes.node,
	as: PropTypes.element,
};
