import environmentConfig from 'moduleAlias/environmentConfig';

import { isNotString, isString } from './string';
import { pipe } from './functional';
import { parseUrl, splitPath } from './link';

export const youtubeEmbedOrigin = `https://www.youtube.com`;
export const youtubeEmbedUrl = `${youtubeEmbedOrigin}/embed/`;

export const YOUTUBE_BUFFERING = 3;
export const YOUTUBE_CUED = 5;
export const YOUTUBE_ENDED = 0;
export const YOUTUBE_PAUSED = 2;
export const YOUTUBE_PLAYING = 1;
export const YOUTUBE_UNSTARTED = -1;

const postMessage = ({ data, targetWindow }) => {
	if (!targetWindow) return;

	const json = JSON.stringify(data);
	targetWindow.postMessage(json, youtubeEmbedOrigin);
};

const sendYoutubeListeningEvent = (targetWindow) => {
	const data = {
		event: 'listening',
		channel: 'widget',
	};

	postMessage({ data, targetWindow });
};

export const sendYoutubeCommandEvent = (targetWindow, func, args) => {
	if (typeof func !== 'string') throw new Error('func should be a string');

	const data = {
		event: 'command',
		func,
		args,
		channel: 'widget',
	};

	return postMessage({ data, targetWindow });
};

const sendYoutubeOnStateChangeListenerEvent = (targetWindow) => {
	const data = {
		event: 'command',
		func: 'addEventListener',
		args: ['onStateChange'],
		channel: 'widget',
	};

	postMessage({ data, targetWindow });
};

export const sendYoutubeInitialEvents = (targetWindow) => {
	// Tell Youtube IFrame that we are listening to some of their events
	sendYoutubeListeningEvent(targetWindow);
	// Tell Youtube IFrame that we are specifically listening to the onStateChange event
	// to make sure the Youtube iFrame will send this event througt the postMessage API
	sendYoutubeOnStateChangeListenerEvent(targetWindow);
};

const getIdFromSearchParams = (url) => {
	return url.searchParams.get('v');
};

const getIdFromShortYoutubeUrl = (url) => {
	const [id] = splitPath(url.pathname);

	return id;
};

const getIdFromLongYoutubeUrl = (url) => {
	const [firstPathSegment, maybeId] = splitPath(url.pathname);

	switch (firstPathSegment) {
		case 'e':
		case 'v':
		case 'embed':
			return maybeId;

		case 'watch':
			return getIdFromSearchParams(url);
		default:
			return undefined;
	}
};

const getIdFromYoutubeUrl = (urlString) => {
	if (isNotString(urlString)) return undefined;

	// base url is to support urls without protocal
	const url = parseUrl(urlString, environmentConfig.originUrl);

	if (url.hostname === 'youtu.be') {
		return getIdFromShortYoutubeUrl(url);
	}

	if (url.hostname.match(/(?:^|www\.)youtube\.com$/)) {
		return getIdFromLongYoutubeUrl(url);
	}

	return undefined;
};

const youtubeIdLength = 11;
const checkYoutubeIdLength = (maybeId) => {
	if (isString(maybeId) && maybeId.length === youtubeIdLength) return maybeId;

	return undefined;
};

export const getYoutubeId = pipe(getIdFromYoutubeUrl, checkYoutubeIdLength);
