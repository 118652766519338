import { css } from 'styled-components';

import {
	hoverSelfSelector,
	hoverDescendantsSelector,
	activeSelfSelector,
	activeDescendantsSelector,
} from '../base/getCSSFromStyleProps';
import { FlattenSimpleInterpolationMaybe } from '../base/StyleProps.types';
import { InteractionZoom } from './interactionDefinitions';

export const getZoomCSS = (
	zoom?: InteractionZoom
): FlattenSimpleInterpolationMaybe => {
	if (!zoom) return;
	const [hoverZoom, activeZoom] = Array.isArray(zoom) ? zoom : [1.04, 0.98];

	return css`
		${hoverSelfSelector},
		${hoverDescendantsSelector} {
			transform: scale(${hoverZoom});
		}

		${activeSelfSelector},
		${activeDescendantsSelector} {
			transform: scale(${activeZoom});
		}
	`;
};
