import React from 'react';

import {
	unexisty,
	getInObject,
	parseJSON,
	isFunction,
} from '@dop/shared/helpers/functional';
import {
	getYoutubeId,
	YOUTUBE_BUFFERING,
	YOUTUBE_CUED,
	YOUTUBE_ENDED,
	YOUTUBE_PAUSED,
	YOUTUBE_PLAYING,
	YOUTUBE_UNSTARTED,
	youtubeEmbedOrigin,
	youtubeEmbedUrl,
	sendYoutubeInitialEvents,
	sendYoutubeCommandEvent,
} from '@dop/shared/helpers/youtubeHelper';
import environmentConfig from 'moduleAlias/environmentConfig';
import { hasWindow } from '@dop/shared/helpers/windowDocument';
import { pushYouTubePlayingEvent } from '@/globals/analytics/events/pushYouTubePlayingEvent';

const originUriComponent = encodeURIComponent(environmentConfig.originUrl);

const useOnYoutubeEvent = ({
	onBuffering,
	onCued,
	onEnded,
	onPaused,
	onPlaying,
	onUnstarted,
}) => {
	const iframeRef = React.createRef();

	const onLoad = () => {
		if (iframeRef.current == null) return;

		const targetWindow = iframeRef.current.contentWindow;

		if (targetWindow == null) return;

		sendYoutubeInitialEvents(targetWindow);
	};

	const handlePostMessage = React.useCallback(
		({ origin, data, source }) => {
			// For security reasons check if origin of message is Youtube
			if (origin !== youtubeEmbedOrigin) return;

			// Check that this message is sent by the iframe in this component
			if (getInObject(['current', 'contentWindow'])(iframeRef) !== source) {
				return;
			}

			const eventData = parseJSON(data);

			if (!eventData) return;

			if (eventData.event !== 'onStateChange') {
				return;
			}

			const callbackMapping = {
				[YOUTUBE_BUFFERING]: onBuffering,
				[YOUTUBE_CUED]: onCued,
				[YOUTUBE_ENDED]: onEnded,
				[YOUTUBE_PAUSED]: onPaused,
				[YOUTUBE_PLAYING]: onPlaying,
				[YOUTUBE_UNSTARTED]: onUnstarted,
			};

			const callback = Object.prototype.hasOwnProperty.call(
				callbackMapping,
				eventData.info
			)
				? callbackMapping[eventData.info]
				: undefined;

			if (isFunction(callback)) {
				callback(eventData);
			}
		},
		[iframeRef, onBuffering, onCued, onEnded, onPaused, onPlaying, onUnstarted]
	);

	React.useEffect(() => {
		if (!hasWindow()) return undefined;

		window.addEventListener('message', handlePostMessage);

		return () => {
			window.removeEventListener('message', handlePostMessage);
		};
	}, [handlePostMessage]);

	return {
		ref: iframeRef,
		onLoad,
		frameBorder: '0',
		allowFullScreen: true,
		allow: 'autoplay',
	};
};

const useYoutubeEventHandlers = ({ onTitleChange }) => {
	const unplayed = React.useRef(true);
	const playerState = React.useRef(null);

	const onUnstarted = React.useCallback(
		(eventData) => {
			playerState.current = YOUTUBE_UNSTARTED;
			const newTitle = getInObject(['info', 'videoData', 'title'])(eventData);

			if (newTitle) {
				onTitleChange(newTitle);
			}
		},
		[onTitleChange]
	);
	const onPlaying = React.useCallback(() => {
		playerState.current = YOUTUBE_PLAYING;
		if (unplayed.current) {
			unplayed.current = false;
			pushYouTubePlayingEvent();
		}
	}, []);
	const onPaused = React.useCallback(() => {
		playerState.current = YOUTUBE_PAUSED;
	}, []);
	const onEnded = React.useCallback(() => {
		playerState.current = YOUTUBE_ENDED;
	}, []);

	return {
		unplayed,
		playerState,
		onUnstarted,
		onPlaying,
		onPaused,
		onEnded,
	};
};
const YoutubeLoader = ({
	videoId,
	title,
	autostart = false,
	isShown,
	pausePlaying,
	allowResumePlaying,
	setAllowResumePlaying = () => {},
	...restProps
}) => {
	const [youtubeTitle, setYoutubeTitle] = React.useState('');
	const [ready, setReady] = React.useState(false);

	// Query string parameters:
	// enablejsapi=1 => allows us to communicate with the iFrame through postMessage
	// disablekb=1 => causes the player to not respond to keyboard controls, needed because it interferes with standard a11y-tools keybindings
	// rel=0 => disables related video's from other channels after the video is finished
	// origin=... => is for security reasons so Youtube can reject postMessages sent from a different origin
	// autoplay=0|1 => autostart playing, needs allow="autoplay" as iframe attribute to function

	const src = `${youtubeEmbedUrl}${videoId}?enablejsapi=1&disablekb=1&rel=0&autoplay=${Number(
		autostart
	)}&origin=${originUriComponent}`;

	const titlePrefix = 'Youtube video';
	const iFrameTitle = title || youtubeTitle;

	const youtubeEventHandlers = useYoutubeEventHandlers({
		onTitleChange: setYoutubeTitle,
	});

	const {
		unplayed: { current: unplayed },
		playerState: { current: playerState },
		onEnded,
	} = youtubeEventHandlers;

	// on component load, check for hasWindow and only then render the youtube video-iframe
	React.useEffect(() => {
		setReady(hasWindow());
	}, []);

	React.useEffect(() => {
		if (!hasWindow()) return undefined;

		return () => {
			onEnded({ videoId });
		};
	}, [videoId, onEnded]);

	const youtubeEventsProps = useOnYoutubeEvent(youtubeEventHandlers);

	// eslint-disable-next-line complexity
	React.useEffect(() => {
		// This useEffect controls the play/pause states when a YouTube video can be controlled outside its iframe.
		// One of the examples is if it is inside a modal popup wich can be closed and reopend.
		// A video can be paused by closing the modal popup but also through the play button of Youtube.
		// Because we only know the state (playing/paused/etc) of the video inside the iframe and
		// if it is shown in our code, we combine this to send play/pause commands to Youtube.
		// Properties:
		// isShown - the video is visible (modal is popped up)
		// pausePlaying - command to pause (true) or play (false) the video
		// allowResumePlaying - flag to resume playing when opened (again)

		if (!hasWindow()) return undefined;

		const targetWindow = getInObject(['ref', 'current', 'contentWindow'])(
			youtubeEventsProps
		);
		if (!targetWindow) return undefined;

		if (!unplayed) {
			// if called with property pausePlaying={true} we will pause.
			if (pausePlaying === true && playerState !== YOUTUBE_PAUSED) {
				sendYoutubeCommandEvent(targetWindow, 'pauseVideo');
			}
			// if called with pausePlaying={false}, we only start playing when allowed to resume
			if (
				isShown &&
				pausePlaying === false &&
				allowResumePlaying === true &&
				playerState !== YOUTUBE_PLAYING
			) {
				sendYoutubeCommandEvent(targetWindow, 'playVideo');
				// we set allowResumePlaying to false for now
				setAllowResumePlaying(false);
			}
		} else if (unplayed && isShown && !pausePlaying) {
			// when opened the first time if it will start because of autoplay
			// we set allowResumePlaying to false for now
			setAllowResumePlaying(false);
		}

		return () => {
			if (!unplayed && !isShown && playerState === YOUTUBE_PLAYING) {
				// when closed and still playing we set allowResumePlaying to true
				setAllowResumePlaying(true);
			}
		};
	}, [
		isShown,
		youtubeEventsProps,
		unplayed,
		playerState,
		pausePlaying,
		allowResumePlaying,
		setAllowResumePlaying,
	]);

	return ready ? (
		<iframe
			{...restProps}
			{...youtubeEventsProps}
			src={src}
			title={iFrameTitle ? `${titlePrefix}: ${iFrameTitle}` : titlePrefix}
		/>
	) : null;
};

export const Youtube = (props) => {
	const videoId = props.videoId || getYoutubeId(props.src);

	if (unexisty(props.id) || unexisty(videoId)) return null;

	return <YoutubeLoader {...props} videoId={videoId} key={videoId} />;
};
