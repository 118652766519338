import React from 'react';

import { getWindow } from '../helpers/windowDocument';

export const useMeasure = () => {
	const ref = React.useRef(null);
	const [bounds, setBounds] = React.useState({
		left: 0,
		top: 0,
		width: 0,
		height: 0,
	});

	React.useEffect(() => {
		const win = getWindow();
		if (!win) return undefined;
		if (!('IntersectionObserver' in win)) return undefined;
		if (!ref.current) return undefined;

		const resizeObserver = new ResizeObserver(([entry]) => {
			if (entry) {
				setBounds(entry.contentRect);
			}
		});

		resizeObserver.observe(ref.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	return { ref, bounds };
};
