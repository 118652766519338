import React from 'react';

import { LinkInline, LinkInlineProps } from './LinkInline';
import { WithLinkTypeIcon } from './WithLinkTypeIcon';

const ContentLinkInline_ = (
	props: LinkInlineProps,
	ref: React.ForwardedRef<null>
): React.ReactElement | null => {
	if (props.href == null) return null;

	return (
		<LinkInline
			$color={['text', 'secondary']}
			$textDecorationLine="underline"
			ref={ref}
			{...props}
		>
			<WithLinkTypeIcon href={props.href}>{props.children}</WithLinkTypeIcon>
		</LinkInline>
	);
};

export const ContentLinkInline = React.forwardRef(ContentLinkInline_);

export type LinkInlineDefaultProps = React.ComponentPropsWithoutRef<
	typeof ContentLinkInline
>;
