import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import {
	getGridTemplateRowsCSS,
	getGridTemplateColumnsCSS,
	getGridAutoRowsCSS,
	getGridAutoColumnsCSS,
	getGridAutoFlowCSS,
	getGridTemplateAreasCSS,
	getGapCSS,
	getAlignItemCSS,
	getJustifyItemsCSS,
	getAlignContentCSS,
	getJustifyContentCSS,
} from './layoutStyleFunctions';
import { Gap } from './layoutDefinitions';

export type GridStaticStyleProps = BaseStaticStyleProps & {
	$rows?: CSSProperty.GridTemplateRows;
	$columns?: CSSProperty.GridTemplateColumns;
	$autoRows?: CSSProperty.GridAutoRows;
	$autoColumns?: CSSProperty.GridAutoColumns;
	$autoFlow?: CSSProperty.GridAutoFlow;
	$areas?: CSSProperty.GridTemplateAreas;
	$gap?: Gap;
	$alignItems?: CSSProperty.AlignItems;
	$justifyItems?: CSSProperty.JustifyItems;
	$alignContent?: CSSProperty.AlignContent;
	$justifyContent?: CSSProperty.JustifyContent;
};

export type GridComponentStyleProps = ComponentStyleProps<GridStaticStyleProps>;

export const gridStaticStyleMap: StyleMap<GridStaticStyleProps> = {
	...baseStaticStyleMap,
	$rows: getGridTemplateRowsCSS,
	$columns: getGridTemplateColumnsCSS,
	$autoRows: getGridAutoRowsCSS,
	$autoColumns: getGridAutoColumnsCSS,
	$autoFlow: getGridAutoFlowCSS,
	$areas: getGridTemplateAreasCSS,
	$gap: getGapCSS,
	$alignItems: getAlignItemCSS,
	$justifyItems: getJustifyItemsCSS,
	$alignContent: getAlignContentCSS,
	$justifyContent: getJustifyContentCSS,
};

const gridStyleMap: ComponentStyleMap<GridStaticStyleProps> = {
	normal: gridStaticStyleMap,
};

/**
 * Grid
 *
 * Component for placing items in 2D space
 *
 * Responsibilities:
 *
 * 1. Arrange items in 2D space via CSS Grid
 * 2. Space between items
 * 3. Allignment of items
 */
export const Grid = styled.span<GridComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: grid;
			list-style: none;
			${getCSSFromStyleProps(gridStyleMap, props)};
		`;
	}};
`;

export type GridProps = StyledComponentPropsWithoutRef<typeof Grid>;
