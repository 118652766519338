import React from 'react';

import {
	TextBlock,
	TextBlockProps,
} from '@dop/ui-primitives/typography/TextBlock';
import {
	mqWidth640px,
	mqWidth880px,
} from '@dop/ui-primitives/base/mediaQueryDefinitions';

export type Level = 1 | 2 | 3 | 4 | 5 | 6;

// For small screens, H1 and H2 will drop down in size some more
const headingLevelCapSizeMap = {
	1: {
		$capSize: [2.4, 1.8],
		$mq: {
			[mqWidth880px.max]: { $capSize: [2, 1.8] },
			[mqWidth640px.max]: { $capSize: [1.7, 1.8] },
		},
	},
	2: {
		$capSize: [1.6, 1.8],
		$mq: {
			[mqWidth880px.max]: { $capSize: [1.4, 1.8] },
			[mqWidth640px.max]: { $capSize: [1.2, 1.8] },
		},
	},
	3: { $capSize: [1.2, 1.8] },
	4: { $capSize: [1.1, 1.8] },
	5: { $capSize: [1, 1.8] },
	6: { $capSize: [0.95, 1.8] },
} as const;

/**
 * Heading typography component
 *
 * A TextBlock with a specific level to (responsive) size mapping and line-height
 *
 * @param props Any props that can be appliedd to a TextBlock
 * @param props.level 1 to 6 (for <h1>, etc...)
 */
const Heading_ = (
	{
		level = 1,
		as = `h${level}`,
		...props
	}: TextBlockProps & { level: Level; as?: `h${Level}` },
	ref: React.ForwardedRef<unknown>
): React.ReactElement | null => {
	return (
		<TextBlock
			as={as}
			ref={ref}
			$fontWeight="bold"
			$fontVariantNumeric="lining-nums"
			$color={['text', 'primary']}
			{...headingLevelCapSizeMap[level]}
			{...props}
		/>
	);
};

export const Heading = React.forwardRef(Heading_);
export type HeadingProps = React.ComponentPropsWithRef<typeof Heading>;
