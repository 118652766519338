import { css } from 'styled-components';

import {
	hoverSelfSelector,
	hoverDescendantsSelector,
	activeSelfSelector,
	activeDescendantsSelector,
} from '../base/getCSSFromStyleProps';
import { FlattenSimpleInterpolationMaybe } from '../base/StyleProps.types';
import { StateColors } from '../colorTheme/ColorTheme.types';
import { getThemedColorValue } from '../colorTheme/colorThemeStyleFunctions';
import { Fill } from './mediaDefinitions';

export const getInteractiveFill =
	(stateName: keyof StateColors) =>
	(fill?: Fill): FlattenSimpleInterpolationMaybe => {
		if (fill == null) return undefined;

		if (Array.isArray(fill) || typeof fill === 'string') {
			return css`
				fill: ${getThemedColorValue(fill, stateName)};
			`;
		}

		// Map values in object to css custom props that can be used in
		// the svg to give different fills to different shapes
		const fillStyles = [];
		for (const [name, value] of Object.entries(fill)) {
			fillStyles.push(css`
            --fill-${name}: ${value};
        `);
		}

		return css`
			${fillStyles}
		`;
	};

export const getNormalFill = (
	fill: Fill = 'var(--color)'
): FlattenSimpleInterpolationMaybe => {
	return css`
		${getInteractiveFill('normal')(fill)}

		${hoverSelfSelector},
        ${hoverDescendantsSelector} {
			${getInteractiveFill('hover')(fill)};
		}

		${activeSelfSelector},
		${activeDescendantsSelector} {
			${getInteractiveFill('active')(fill)};
		}
	`;
};
