export const capHeight = [
	0.75, 0.875, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.7, 1.9, 2.2,
] as const;

/**
 * CapHeight in `var(--root-cap)`
 *
 * Is converted to pixels and used as [Capsize capHeight option](https://github.com/seek-oss/capsize#capheight-number)
 */
export type CapHeight = typeof capHeight[number];

export type CapSize = CapHeight | [CapHeight, LeadingRatio];

export const leadingRatio = [1.8, 2.4] as const;

/**
 * Leading ratio
 *
 * Is multiplied by the cap height and then used as [Capsize leading option](https://github.com/seek-oss/capsize#leading-number)
 *
 * `2.4` is the default and used for body copy.
 *
 * `1.8` is used for headings, texts with a small line width and interface elements such as buttons
 */
export type LeadingRatio = typeof leadingRatio[number];

export const fontVariantNumeric = ['oldstyle-nums', 'lining-nums'] as const;

export type FontVariantNumeric = typeof fontVariantNumeric[number];

export const defaultTextTransition = `color 200ms`;
