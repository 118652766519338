import styled from 'styled-components';

import {
	mq,
	extraSmall,
	small,
	smallMedium,
} from '@dop/shared/styleHelpers/mediaQueries';

import { grijs } from '../styles/colors';

export const EmbedContainer = styled.span`
	width: 100%;
	height: 0;
	position: relative;
	padding-bottom: 56.25%;
	display: block;
`;

export const EmbedContainerMediaWrapper = styled.span`
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
`;

export const EmbedContainerMedia = styled.iframe`
	display: block;
	width: 100%;
	height: 100%;
`;

export const EmbedContainerOverlay = styled.span`
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: ${grijs.light};
	padding: 0.25rem 0.5rem;
	${mq({ from: smallMedium })`
		padding: 1rem 4rem;
	`}
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;

	& > p {
		font-size: x-small;
		${mq({ from: extraSmall })`
			font-size: small;
		`}
		${mq({ from: small })`
			font-size: revert;
		`}
	}
`;
