import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import { Gap } from './layoutDefinitions';
import {
	getGapCSS,
	getAlignItemCSS,
	getAlignContentCSS,
	getJustifyContentCSS,
} from './layoutStyleFunctions';

export type RowsStaticStyleProps = BaseStaticStyleProps & {
	$gap?: Gap;
	$alignItems?: CSSProperty.AlignItems;
	$alignContent?: CSSProperty.AlignContent;
	$justifyContent?: CSSProperty.JustifyContent;
};

export type RowsComponentStyleProps = ComponentStyleProps<RowsStaticStyleProps>;

export const rowsStaticStyleMap: StyleMap<RowsStaticStyleProps> = {
	...baseStaticStyleMap,
	$gap: getGapCSS,
	$alignItems: getAlignItemCSS,
	$alignContent: getAlignContentCSS,
	$justifyContent: (justifyContent = 'start') =>
		getJustifyContentCSS(justifyContent),
};

const rowsStyleMap: ComponentStyleMap<RowsStaticStyleProps> = {
	normal: rowsStaticStyleMap,
};

/**
 * Rows can be used to place items over wrapping rows when items should
 * flow after each other instead of being placed in a grid.
 * Flexbox is used to achieve this goal.
 *
 * Responsibilities:
 *
 * 1. Place items and allow them to flow over multiple rows
 * 2. Space between items
 * 3. Alignment of items
 */
export const Rows = styled.span<RowsComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: flex;
			flex-flow: row wrap;

			${getCSSFromStyleProps(rowsStyleMap, props)};
		`;
	}};
`;

export type RowsProps = StyledComponentPropsWithoutRef<typeof Rows>;
