import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import {
	defaultTextTransition,
	FontVariantNumeric,
} from './typographyDefinitions';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import {
	getNormalTextColorCSS,
	getFontWeightCSS,
	getFontVariantNumericCSS,
	getFontStyleCSS,
	getTextDecorationLineCSS,
	getTextTransformCSS,
} from './typographyStyleFunctions';
import {
	getDisplayCSS,
	getVerticalAlignCSS,
} from '../base/commonStyleFunctions';
import { TextColor } from '../colorTheme/ColorTheme.types';

export type TextInlineStaticStyleProps = Omit<
	BaseStaticStyleProps,
	'$display'
> & {
	$display?: Extract<
		CSSProperty.Display,
		CSSProperty.All | 'none' | 'inline-block'
	>;
	$verticalAlign?: CSSProperty.VerticalAlign;
	$fontVariantNumeric?: FontVariantNumeric;
	$fontStyle?: CSSProperty.FontStyle;
	$fontWeight?: CSSProperty.FontWeight;
	$textDecorationLine?: Extract<
		CSSProperty.TextDecorationLine,
		CSSProperty.All | 'none' | 'underline'
	>;
	$color?: TextColor;
	$textTransform?: CSSProperty.TextTransform;
};

export type TextInlineComponentStyleProps =
	ComponentStyleProps<TextInlineStaticStyleProps>;

export const textInlineStaticStyleMap: StyleMap<TextInlineStaticStyleProps> = {
	...baseStaticStyleMap,
	$transition: (transition = defaultTextTransition) =>
		baseStaticStyleMap.$transition(transition),
	$display: getDisplayCSS,
	$verticalAlign: getVerticalAlignCSS,
	$fontWeight: getFontWeightCSS,
	$fontStyle: getFontStyleCSS,
	$fontVariantNumeric: getFontVariantNumericCSS,
	$textDecorationLine: getTextDecorationLineCSS,
	$color: getNormalTextColorCSS,
	$textTransform: getTextTransformCSS,
};

const textInlineStyleMap: ComponentStyleMap<TextInlineStaticStyleProps> = {
	normal: textInlineStaticStyleMap,
};

/**
 * Component for styling inline text
 *
 * Responsibilities:
 *
 * 1. Text styling for inline text
 *
 * Note: This does _not_ take care of fitting spacing snugly around text.
 * It should therefor be used as an descendant of `<TextBlock/>`
 */
export const TextInline = styled.span<TextInlineComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			${getCSSFromStyleProps(textInlineStyleMap, props)};
		`;
	}};
`;

export type TextInlineProps = StyledComponentPropsWithoutRef<typeof TextInline>;
