import React from 'react';

import { themify } from '@dop/shared/helpers/reactHelpers';

// General Heading Component
// Can render headings from h1 to h6 determined by level

const getHeadingName = (level) => `h${level}`;

const HeadingBase = (
	{
		className,
		theme,
		level,
		styleLevel = level,
		children,
		// pageNumber werd doorgegeven aan HTML element via ...props en gaf foutmelding:
		pageNumber: _pageNumber,
		...props
	},
	ref
) => {
	if (level == null || children == null) return null;

	const headerProperties = {
		ref,
		className: themify('heading', theme, getHeadingName(styleLevel), className),
		...props,
	};

	return React.createElement(getHeadingName(level), headerProperties, children);
};

/**
 * @deprecated - use @dop/ui-composites/Heading instead
 */
export const Heading = React.forwardRef(HeadingBase);
