import React from 'react';

import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import {
	TextBlock,
	TextBlockProps,
} from '@dop/ui-primitives/typography/TextBlock';
import { Svg, SvgProps } from '@dop/ui-primitives/media/Svg';

import { LinkBlock, LinkBlockProps } from './LinkBlock';

export const ShelfLinkIcon = (props: SvgProps) => {
	return (
		<Svg
			$inlineSize={'auto'}
			$blockSize={1}
			$fill={['text', 'secondary']}
			{...props}
		/>
	);
};

export const ShelfLinkText = ({
	children,
	...props
}: TextBlockProps): React.ReactElement | null => {
	return (
		<TextBlock
			$hover={{ $textDecorationLine: 'underline' }}
			$color={['text', 'secondary']}
			$capSize={[1, 1.8]}
			{...props}
		>
			{children}
		</TextBlock>
	);
};

/**
 * Use with ShelfLinkText and ShelfLinkIcon as children
 */
export const ShelfLinkBlock = React.forwardRef(
	(
		{ children, ...props }: LinkBlockProps,
		ref: React.ForwardedRef<HTMLAnchorElement>
	): React.ReactElement | null => {
		return (
			<LinkBlock ref={ref} {...props}>
				<Shelf $gap={1} $alignItems="start">
					{children}
				</Shelf>
			</LinkBlock>
		);
	}
);

export type ShelfLinkBlockProps = React.ComponentPropsWithRef<
	typeof ShelfLinkBlock
>;
