import React from 'react';

import {
	ImagePrimitive,
	ImagePrimitiveProps,
} from '@dop/ui-primitives/media/ImagePrimitive';
import { neutral } from '@dop/ui-primitives/color/colors';
import { AsyncState } from '@dop/shared/redux/redux.types';

import {
	getResponsiveSrc,
	getSrcSet,
	ImageSetEntryName,
	isBloomreachCDN,
} from './imageSrcSet';

export type ImageProps = Omit<
	ImagePrimitiveProps,
	'srcSet' | 'src' | 'sizes'
> & {
	/**
	 * The `src` prop will be converted to a responsive srcSet
	 * when `imageSet` is provided and the `src` url refers to
	 * an image on the Bloomreach CDN.
	 */
	src: string;
	srcSet?: string;
} & (
		| {
				imageSet: Array<ImageSetEntryName>;
				sizes: ImagePrimitiveProps['sizes'];
		  }
		| {
				imageSet?: undefined;
				sizes?: undefined;
		  }
	);

const ImageLoading_ = (
	props: ImagePrimitiveProps,
	forwardedRef: React.ForwardedRef<React.ElementRef<'img'>>
) => {
	const ref = React.useRef<React.ElementRef<'img'>>(null);
	const [asyncState, setAsyncState] = React.useState<AsyncState>('pending');

	React.useImperativeHandle(
		forwardedRef,
		() => ref.current as React.ElementRef<'img'>
	);

	React.useEffect(() => {
		if (ref.current == null) return;

		if (ref.current.complete) {
			setAsyncState('fulfilled');
			return;
		}

		const abortController = new AbortController();

		ref.current.addEventListener('load', () => setAsyncState('fulfilled'), {
			once: true,
			signal: abortController.signal,
		});
		ref.current.addEventListener('error', () => setAsyncState('rejected'), {
			once: true,
			signal: abortController.signal,
		});

		return () => {
			abortController.abort();
		};
	}, []);

	return (
		<ImagePrimitive
			ref={ref}
			$backgroundColor={asyncState === 'fulfilled' ? undefined : neutral[60]}
			{...props}
		/>
	);
};

const ImageLoading = React.forwardRef(ImageLoading_);

const Image_ = (
	{ src, imageSet, ...props }: ImageProps,
	ref: React.ForwardedRef<React.ElementRef<'img'>>
) => {
	if (
		imageSet != null &&
		// If srcSet is provided manually don't overwrite it
		// with a srcSet of Bloomreach images.
		props.srcSet == null &&
		!src.toLowerCase().endsWith('.svg') && // only provide srcSet for non-svg images
		isBloomreachCDN(src)
	) {
		const srcSet = getSrcSet(src, imageSet);
		const responsiveSrc = getResponsiveSrc(src, imageSet[imageSet.length - 1]);

		return (
			<ImageLoading
				ref={ref}
				{...props}
				src={responsiveSrc ?? src}
				srcSet={srcSet}
			/>
		);
	}

	return <ImagePrimitive ref={ref} {...props} src={src} />;
};

/**
 * Component for responsive images including support for Bloomreach images.
 *
 * The `src` prop will be converted to a responsive `srcSet`
 * when `imageSet` is provided and the `src` url refers to
 * an image on the Bloomreach CDN.
 *
 * `srcSet` in combination with `sizes` lets the browser
 * decide which image is the most appropriate to use.
 * If there is a need for an art direction approach in
 * the future the `<picture>` element should be used instead.
 * However right now we do not take that approach.
 *
 * Values for the `sizes` prop can be imported from `./imageSizes.tsx`.
 */
export const Image = React.forwardRef(Image_);
